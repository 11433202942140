import React, { Dispatch } from "react";
import { GetData, GetFilteredData, IFilterType, Property } from "../types/Models";
type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
    }
    : {
        type: Key;
        payload: M[Key];
    };
};

export enum Actions {
    AllChanged = "ALL_DATA_CHANGED",
    PageChanged = "PAGE_CHANGED",
    PropertySelection = "PROPERTY_SELECTED",
    FilterChanged = "FILTER_CHANGED",
    SetLoading = "SET_LOADING",
}

export type PropertiesPayload = {
    [Actions.AllChanged]: any[];
    [Actions.PageChanged]: number;
    [Actions.PropertySelection]: number;
    [Actions.FilterChanged]: IFilterType;
    [Actions.SetLoading]: boolean;
};

export type PropertiesActions =
    ActionMap<PropertiesPayload>[keyof ActionMap<PropertiesPayload>];

interface State {
    allData: Property[];
    filteredData: Property[];
    pagedData: Property[];
    pageSize: number;
    pageNumber: number;
    pageCount: number;
    selectedIndex: number;
    selectedProperty?: Property;
    categories: string[];
    areas: Object;
    prices: Object;
    beds: Object;
    filters: IFilterType;


    dataReady: Function;
    pageChanged: Function;
    selectionChanged: Function;
    loading: boolean;
}


const initialState: State = {
    allData: [], pagedData: [], filteredData: [], pageSize: 12,
    pageNumber: 0, pageCount: 5,
    dataReady: () => { }, pageChanged: () => { },
    selectedIndex: 0,
    selectedProperty: undefined,
    selectionChanged: () => { },
    categories: [],
    areas: { min: 0, max: 0, step: 0 },
    beds: { min: 0, max: 0, step: 0 },
    prices: { min: 0, max: 0, step: 0 },
    filters: { area: [], price: [], beds: [], category: 'All' },
    loading: false
};

export const reducer = (state: State,
    action: PropertiesActions): State => {
    switch (action.type) {
        case Actions.SetLoading:
            return {
                ...state,
                loading: action.payload
            }
        case Actions.AllChanged:
            localStorage.setItem('data', JSON.stringify(action.payload))
            var data = GetData(action.payload)
            return {
                ...state, allData: action.payload, pageSize: 12,
                filteredData: action.payload,
                pageCount: Math.ceil(action.payload.length / state.pageSize),
                pagedData: action.payload.slice(0, 12),
                selectedIndex: 0,
                selectedProperty: action.payload[0],
                categories: data.Categories,
                areas: data.Areas,
                beds: data.Beds,
                prices: data.Prices,
                filters: { area: [data.Areas.min, data.Areas.max], price: [data.Prices.min, data.Prices.max], beds: [data.Beds.min, data.Beds.max], category: 'All' }
            };
        case Actions.PageChanged: {
            const start = (action.payload - 1) * state.pageSize
            const end = start + state.pageSize
            const pagedData = state.filteredData.slice(start, end)
            const newS = { ...state, pageNumber: action.payload, pagedData: pagedData };

            return newS;
        }

        case Actions.PropertySelection: {
            const selectedIndex = action.payload + (state.pageNumber * state.pageSize);
            const selectedProperty = state.allData[selectedIndex];
            return { ...state, selectedIndex: selectedIndex, selectedProperty: selectedProperty };

        }
        case Actions.FilterChanged: {
            // here we will filter the data
            var filteredData = GetFilteredData([...state.allData], action.payload)



            return {
                ...state,
                filters: action.payload,
                filteredData: filteredData,
                pageCount: Math.ceil(filteredData.length / state.pageSize),
                selectedIndex: 0,
                selectedProperty: filteredData[0],

                pageNumber: 0,
                pagedData: filteredData.slice(0, 12)
            };

        }
        default:
            return state;
    }
};

export const PropertiesContext = React.createContext<{
    state: State;
    dispatch: Dispatch<PropertiesActions>;
}>({
    state: initialState,
    dispatch: () => null,
});
