import React from "react";
import { Property } from "../types/Models";

const ListInfo = ({ selectedProperty }: { selectedProperty: Property }) => {
    return (
        <>
            <ul className="flex flex-col text-secondary">
                {selectedProperty.reference && (
                    <li className="flex flex-row justify-between border-b border-secondary py-[10px] ">
                        <span>Referenz</span>
                        <span>{selectedProperty.reference}</span>
                    </li>
                )}

                {selectedProperty.nr_bathrooms && (
                    <li className="flex flex-row justify-between border-b border-secondary py-[10px] ">
                        <span>Badezimmer</span>
                        <span>{selectedProperty.nr_bathrooms}</span>
                    </li>
                )}

                {selectedProperty.dt_construction_year && (
                    <li className="flex flex-row justify-between border-b border-secondary py-[10px] ">
                        <span>Baujahr</span>
                        <span>{selectedProperty.dt_construction_year}</span>
                    </li>
                )}

                {selectedProperty.nr_bedrooms && (
                    <li className="flex flex-row justify-between border-b border-secondary py-[10px] ">
                        <span>Schlafzimmer</span>
                        <span>{selectedProperty.nr_bedrooms}</span>
                    </li>
                )}
                {selectedProperty.mts_build && (
                    <li className="flex flex-row justify-between border-b border-secondary py-[10px] ">
                        <span>Grundstückfläche</span>
                        <span>{selectedProperty.mts_build}</span>
                    </li>
                )}
            </ul>
        </>
    );
};

export default ListInfo;
