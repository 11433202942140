import React from 'react';
import { useReducer } from 'react';
import Details from './pages/Details';
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import { Actions, PropertiesContext, reducer } from './context/properties.context';
import axios from 'axios';
import { Property } from './types/Models';


function App() {

  const [state, dispatch] = useReducer(reducer, {
    allData: [], filteredData: [], pagedData: [], pageSize: 12,
    pageNumber: 0, pageCount: 5,
    dataReady: () => { console.log(1); }, pageChanged: () => { },
    selectedIndex: 0,
    selectedProperty: undefined,
    selectionChanged: () => { },
    categories: [],
    areas: { min: 0, max: 0, step: 0 },
    beds: { min: 0, max: 0, step: 0 },
    prices: { min: 0, max: 0, step: 0 },
    filters: { price: null, area: null, beds: null, category: 'All' },
    loading: false
  });

  React.useEffect(() => {
    dispatch({
      type: Actions.SetLoading,
      payload: true
    })
    axios
      .get("https://life-style-api-lwhov6eauq-ue.a.run.app/getdata")
      .then((res) => {
        let x = res.data;
        return x;
      })
      .then((data: Property[]) => {
        dispatch({
          type: Actions.SetLoading,
          payload: false
        })
        dispatch({
          type: Actions.AllChanged,
          payload: data,
        });
      })
      .catch((err) => console.log(err));
  }, [])
  return (

    <>
      {
        state.loading && <div className="absolute top-2/4 left-2/4 h-screen w-screen">
          <svg className="relative" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="45" />
          </svg>
        </div>}

      <PropertiesContext.Provider value={{
        state, dispatch
      }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="details/:id" element={<Details />}>
          </Route>

        </Routes>
      </PropertiesContext.Provider>
    </>
  );
}

export default App;
