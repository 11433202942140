import React from 'react';

interface CardsGrid {
    children: React.ReactNode,
}
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }: CardsGrid) => {
    return (
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
            {children}
        </div>
    )
}