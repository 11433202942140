import React from 'react';
import { Option } from '../types/Models';

const ITEMS_SHOW_MORE_COUNT = 10;

interface CharacteristicsListProps {
    options: Option[]
}
const CharacteristicsList = ({ options }: CharacteristicsListProps) => {

    const [showAll, setShowAll] = React.useState(false);
    return <section className="mb-4 ">
        <ul className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 grid-rows-1 gap-2 pl-4 " >
            {options && options.slice(0, !showAll ? ITEMS_SHOW_MORE_COUNT : options.length).map(({ option }) => {
                return <li className="list-disc">{option}</li>
            })}

        </ul>
        <button onClick={() => setShowAll(!showAll)} className="underline font-medium mt-4">{showAll ? 'Weniger zeigen' : 'Mehr zeigen'}</button>
    </section>


}

export default CharacteristicsList;