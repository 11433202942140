import React from 'react';
import Input from './input';

interface DialogProps {
    done?: boolean
    iserror?: boolean
    submit: (data: any) => void;
}
export const Dialog = (props: DialogProps) => {

    const [vorName, setVorName] = React.useState<string | undefined>();
    const [email, setEmail] = React.useState<string | undefined>();
    const [name, setName] = React.useState<string | undefined>();
    const [comments, setComments] = React.useState<string | undefined>();
    const formRef = React.useRef<HTMLFormElement>(null)
    // add here React.useState() for the values of the inputs.
    const closeModal = () => {
        const propertyDialog = document.getElementById('property-dialog') as HTMLDialogElement;
        if (typeof propertyDialog.showModal === 'function') {
            propertyDialog.close();
        }
    }
    return (
        <>
            <dialog className="w-2/3  p-7 relative lg:max-w-[700px]" id="property-dialog">
                {/* {props.done && <>
                    <div className="flex flex-row justify-between mb-4 items-center">
                        <h2 className="text-2xl font-medium">Bestätigung
                        </h2>
                        <button className="" onClick={closeModal}>
                            <span className="material-symbols-outlined block">
                                close
                            </span>
                        </button>
                    </div>

                    <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
                        <span className="font-medium">Ihre Anfrage wurde an die Agentur gesendet!</span>
                    </div>
                </>} 
                */}
                {/* {props.iserror && <>
                    <div className="flex flex-row justify-between mb-4 items-center">
                        <h2 className="text-2xl font-medium">Fehler
                        </h2>
                        <button className="" onClick={closeModal}>
                            <span className="material-symbols-outlined block">
                                close
                            </span>
                        </button>
                    </div>

                    <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                        <span className="font-medium">Fehler, Daten wurden nicht gesendet</span>
                    </div>
                </>} */}

                {<>
                    <div className="flex flex-row justify-between mb-4 items-center">
                        <h2 className="text-2xl font-medium">Kontaktformular
                        </h2>
                        <button className="" onClick={closeModal}>
                            <span className="material-symbols-outlined block">
                                close
                            </span>
                        </button>
                    </div>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        props.submit({
                            vorName,
                            email,
                            name,
                            comments
                        })
                    }
                    } ref={formRef} className="flex flex-col gap-3" method="dialog">


                        <Input type={'email'} name='email' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required value={email} onChange={(e) => setEmail(e.target.value)} className="mt-2" placeholder="Email" />
                        <Input type={'text'} name='sername' required value={vorName} onChange={(e) => setVorName(e.target.value)} className="mt-2" placeholder="Vorname" />
                        <Input type={'text'} name='name' required value={name} onChange={(e) => setName(e.target.value)} className="mt-2" placeholder="Name" />
                        <textarea name='notes' className="form-control mt-2 p-3 focus:border-secondary focus:border" value={comments} onChange={(e) => setComments(e.target.value)} placeholder="Comments" />


                        <button type="submit" className="btn btn-primary w-full mt-2">Anfrage senden</button>
                        {props.done &&
                            <div className="p-4 text-sm text-green-100 bg-green-700 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
                                <span className="font-medium">Ihre Anfrage wurde an die Agentur gesendet!</span>
                            </div>
                        }

                        {props.iserror &&
                            <div className="p-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                                <span className="font-medium">Fehler, Daten wurden nicht gesendet</span>
                            </div>
                        }
                    </form>
                </>

                }

            </dialog>
        </>
    )
}