import React from "react";
import { Actions, PropertiesContext } from "../../context/properties.context";
import RangeComp from "./Range";
import RangeWraper from "./RangeWraper";
import "rc-slider/assets/index.css";

type Props = {};

function FiltersWraper({ }: Props) {
    const { state, dispatch } = React.useContext(PropertiesContext);
    const [rangesData, setRangesData] = React.useState({
        area: undefined,
        price: undefined,
        bed: undefined,
    });
    return (
        <header className="bg-primary">
            <section className="content-container items-center grid lg:grid-cols-3 sm:grid-cols-2  md:grid-cols-2 py-[30px]  grid-cols-1 text-secondary gap-8">
                {/*  area Range */}

                <RangeWraper>
                    <label className="mb-2 block" htmlFor="arearange">
                        Kategorie
                    </label>

                    <select
                        className="form-control w-full mt-0 border-secondary border"
                        value={state.filters.category}
                        onChange={(e) => {
                            dispatch({
                                type: Actions.FilterChanged,
                                payload: { ...state.filters, category: e.target.value },
                            });
                        }}>
                        {state.categories.map((x, y) => (
                            <option key={y}>{x}</option>
                        ))}
                    </select>
                </RangeWraper>
                <RangeWraper>
                    <div className="flex flex-row justify-between text-secondary ">
                        <label className="-ml-[5px] mb-2 block" htmlFor="arearange">
                            Wohnfläche
                        </label>

                        {state?.filters?.area && (
                            <span className=" italic font-light">
                                Von{" "}
                                <span className="font-medium">
                                    {state.filters.area[0].toLocaleString()}
                                    <span>&#13217;</span>{" "}
                                </span>
                                bis{" "}
                                <span className="font-medium">
                                    {state.filters.area[1].toLocaleString()}
                                    <span>&#13217;</span>
                                </span>
                            </span>
                        )}
                    </div>
                    <RangeComp
                        min={(state.areas as any).min}
                        max={(state.areas as any).max}
                        step={(state.areas as any).step}
                        value={state.filters.area}
                        onAfterChange={(range: any) => {
                            dispatch({
                                type: Actions.FilterChanged,
                                payload: { ...state.filters, area: range },
                            });
                            setRangesData((curr) => ({
                                ...curr,
                                area: range,
                            }));
                        }}></RangeComp>
                </RangeWraper>

                {/*  price Range */}
                <RangeWraper>
                    <div className="flex flex-row justify-between text-secondary ">
                        <label className="-ml-[5px] mb-2 block" htmlFor="arearange">
                            Preis
                        </label>

                        {state?.filters?.price && (
                            <span className=" italic font-light">
                                Von <span className="font-medium">{state.filters.price[0].toLocaleString()} €</span> bis{" "}
                                <span className="font-medium">{state.filters.price[1].toLocaleString()} €</span>
                            </span>
                        )}
                    </div>
                    <RangeComp
                        min={(state.prices as any).min}
                        max={(state.prices as any).max}
                        step={(state.prices as any).step}
                        value={state.filters.price}
                        onAfterChange={(range: any) => {
                            dispatch({
                                type: Actions.FilterChanged,
                                payload: { ...state.filters, price: range },
                            });
                            setRangesData((curr) => ({
                                ...curr,
                                price: range,
                            }));
                        }}></RangeComp>
                </RangeWraper>

                {/*  bed Range */}
                <RangeWraper>
                    <div className="flex flex-row justify-between text-secondary ">
                        <label className="-ml-[5px] mb-2 block" htmlFor="arearange">
                            Schlafzimmer
                        </label>

                        {state?.filters?.beds && (
                            <span className=" italic font-light">
                                Von <span className="font-medium">{state.filters.beds[0]}</span> bis{" "}
                                <span className="font-medium">{state.filters.beds[1]}</span>
                            </span>
                        )}
                    </div>
                    <RangeComp
                        min={(state.beds as any).min}
                        max={(state.beds as any).max}
                        step={(state.beds as any).step}
                        value={state.filters.beds}
                        onAfterChange={(range: any) => {
                            dispatch({
                                type: Actions.FilterChanged,
                                payload: { ...state.filters, beds: range },
                            });

                            setRangesData((curr) => ({
                                ...curr,
                                bed: range,
                            }));
                        }}></RangeComp>
                </RangeWraper>
            </section>
        </header>
    );
}

export default FiltersWraper;
