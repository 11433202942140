export interface Istatet {
    allData: any[];
    filteredData: any[];
    pagedData: any[];
    pageSize: number;
    pageNumber: number;
    pageCount: number;
    selectedIndex: number;
    selectedProperty: any;

    dataReady: Function;
    pageChanged: Function;
    selectionChanged: Function;
}

export interface IRangeData {
    min: number,
    max: number,
    step: number
}

export interface IFilterType {
    area: number[] | any,
    price: number[] | any,
    beds: number[] | any,
    category: string
}


export function GetData(data: Property[]): { Areas: IRangeData, Prices: IRangeData, Beds: IRangeData, Categories: string[] } {
    var cats = ['All']
    var areas: number[] = []
    var beds: number[] = []
    var prices: number[] = []
    data.forEach(element => {
        const v = element.propertytype.value;
        if (!cats.includes(v)) {
            cats.push(v)
        }
        areas.push(+element.mts_build)
        beds.push(+element.nr_bedrooms)
        prices.push(+element.selling_price_eur)
    });

    return {
        Areas: { min: Math.min.apply(null, areas), max: Math.max.apply(null, areas), step: 100 },
        Prices: { min: Math.min.apply(null, prices), max: Math.max.apply(null, prices), step: 50000 },
        Beds: { min: Math.min.apply(null, beds), max: Math.max.apply(null, beds), step: 1 },
        Categories: cats
    }

}
export function GetFilteredData(data: Property[], filters: IFilterType): any[] {
    if (filters.price?.length > 0) {
        data = data.filter(r => r.selling_price_eur >= filters.price[0])
        data = data.filter(r => r.selling_price_eur <= filters.price[1])
    }
    if (filters.area?.length > 0) {
        data = data.filter(r => +r.mts_build >= filters.area[0])
        data = data.filter(r => +r.mts_build <= filters.area[1])
    }
    if (filters.beds?.length > 0) {
        data = data.filter(r => +r.nr_bedrooms >= filters.beds[0])
        data = data.filter(r => +r.nr_bedrooms <= filters.beds[1])
    }
    if (filters.category !== 'All') {
        data = data.filter(r => r.propertytype.value === filters.category)

    }
    return data;
}


// Generated by https://quicktype.io

export interface Property {
    reference: string;
    client_ref_num: string;
    dt_create_date: string;
    dt_last_updated: string;
    dt_added_date: string;
    dt_review_date: string;
    is_new_property: string;
    is_exclusive: string;
    is_featured: string;
    is_direct: string;
    is_hot: string;
    is_luxury: string;
    is_for_sale: string;
    is_sold: string;
    is_special: string;
    is_for_rent: string;
    is_for_rent_long: string;
    is_for_rent_short: string;
    is_rented: string;
    propertytype: City;
    propertycategory: City;
    development_reference: DevelopmentReference;
    country: City;
    province: City;
    city: City;
    area: City | string;
    subarea: City | string;
    postcode: string;
    community: string;
    ibi: string;
    garbage: string;
    tr_orientation: TrOrientation;
    mts_build: string;
    mts_plot: string;
    mts_interior: string;
    mts_terrace: string;
    nr_floors: string;
    nr_level: string;
    nr_bedrooms: string;
    nr_bathrooms: string;
    nr_onsuite_bathrooms: string;
    nr_toilet: string;
    tr_garage: City | string;
    nr_parking_spaces: string;
    tr_garden: City | string;
    tr_pool: City | string;
    dt_construction_year: string;
    energy: Energy;
    touristic_code: string;
    selling_price_eur: string;
    rental_price_eur: string;
    rental_price_eur_long: string;
    selling_price: SellingPrice;
    rental_price: RentalPrice;
    rental_price_long: RentalPrice;
    rental_price_comment: string;
    rental_price_comment_long: string;
    images: Image[];
    longitude: string;
    latitude: string;
    agency_id: string;
    listed_by: ListedBy;
    listed_by2: ListedBy | string;
    options: Options;
    descriptions: Descriptions;
    files: FilesClass | string;
    links: string;
    htmls: string;
}

export interface City {
    id: string;
    value: string;
}

export interface Descriptions {
    short_description: Description;
    long_description: Description;
    price_description: PriceDescription;
}

export interface Description {
    _: string;
    lang: Lang;
}

export enum Lang {
    De = "de",
}

export interface PriceDescription {
    lang: Lang;
}

export enum DevelopmentReference {
    Empty = "",
    The25800072G = "258-00072G",
}

export interface Energy {
    inProcess: string;
}

export interface FilesClass {
    file: FileElement[] | FileElement;
}

export interface FileElement {
    _: string;
    lang: string;
    type: string;
    url: string;
}

export interface Image {
    _: string;
    url: string;
    main: string;
}

export interface ImagesClass {
    url: string;
    main: string;
    value: string;
}

export interface ListedBy {
    id: string;
    name: Name;
    surname: Surname;
}

export enum Name {
    Andy = "Andy",
    Carmen = "Carmen",
    Maria = "Maria",
    Mauro = "Mauro",
}

export enum Surname {
    Fernández = "Fernández",
    George = "George",
    Martinez = "Martinez",
    Volgareva = "Volgareva",
}

export interface Options {
    option: Option[];
}

export interface Option {
    _: string;
    type: string;
    option: string;
}

export interface RentalPrice {
    currency: Currency;
}

export enum Currency {
    Eur = "eur",
}

export interface SellingPrice {
    currency: Currency;
    value: string;
}

export enum TrOrientation {
    East = "East",
    Empty = "",
    NorthEast = "North/East",
    NorthWest = "North/West",
    South = "South",
    SouthEast = "South/East",
    SouthWest = "South/West",
    West = "West",
}
