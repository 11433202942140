import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Carousel from "../components/carousel";
import CharacteristicsList from "../components/characteristics-list";
import Contact from "../components/contact";
import { Dialog } from "../components/dialog";
import IconInfo from "../components/icon-info";
import ListInfo from "../components/info-list";
import { Actions, PropertiesContext } from "../context/properties.context";
import { Image, Property } from "../types/Models";
import axios from 'axios';


const Details = () => {
    const params = useParams();
    const { state, dispatch } = React.useContext(PropertiesContext);
    const [property, setProperty] = useState<Property>();
    const [propertyData, setPropertyData] = useState<any>({});
    const [propertyNavigation, setPropertyNavigation] = useState<any>({});

    const [done, setDone] = useState(false)
    const [iserror, setisError] = useState(false)
    const [sending, setsending] = useState(false)

    const handleProperty = () => {
        setDone(false)
        setisError(false)
        const propertyDialog = document.getElementById(
            "property-dialog",
        ) as HTMLDialogElement;
        if (typeof propertyDialog.showModal === "function") {
            propertyDialog.showModal();
        }
    };
    React.useEffect(() => {
        const propertyReference = params.id;
        if (state.filteredData.length) {
            const property = state.filteredData.filter(
                (prop) => prop.reference === propertyReference,
            );
            const propertyIndex = state.filteredData.findIndex(
                (prop) => prop.reference === propertyReference,
            );
            dispatch({ type: Actions.PropertySelection, payload: propertyIndex });

            if (propertyIndex !== -1) {
                setPropertyNavigation({
                    nextPropertyReference:
                        propertyIndex < state.filteredData.length - 1
                            ? state.filteredData[propertyIndex + 1].reference
                            : null,
                    previousPropertyReference:
                        propertyIndex > 0 ? state.filteredData[propertyIndex - 1].reference : null,
                });
            }
            if (property.length) {
                let foundProperty = property[0];
                setProperty(foundProperty);
                setPropertyData({
                    images:
                        foundProperty && foundProperty.images.length > 0
                            ? foundProperty.images?.map((i: Image) => i.url)
                            : [],
                    price: (+foundProperty.selling_price_eur).toLocaleString(),
                    area: foundProperty.mts_build,
                    beds: foundProperty.nr_bedrooms,
                    dateBuid: foundProperty.dt_construction_year,
                    shortDescription: foundProperty.descriptions.short_description._,
                    longDescription: foundProperty.descriptions.long_description._,
                    numberOfBathRooms: foundProperty.nr_bathrooms,
                    location: foundProperty.city.value,
                    community: foundProperty.community,
                });
            }
        }
    }, [dispatch, params.id, state.filteredData]);

    return (
        <>
            <Dialog
                submit={(data) => {
                    const propertyReference = params.id;
                    setDone(false);
                    setisError(false);
                    console.log(data, propertyReference);
                    axios.post('https://life-style-api-lwhov6eauq-ue.a.run.app/sendmail',
                        {
                            unitid: propertyReference,
                            customerEmail: data.email,
                            customerVorName: data.vorName,
                            customerNotes: data.comments, customerName: data.name
                        })
                        .then(r => setDone(true))
                        .catch(r => setisError(true))
                    // setDone(true)
                    // setisError(true)

                }}
                done={done}
                iserror={iserror}
            />
            {property && (
                <>
                    <header className={" bg-primary text-secondary "}>
                        <ul className="flex flex-row  justify-center p-6 text-xs lg:text-base">
                            {propertyNavigation?.previousPropertyReference && (
                                <li className="border-r-2 px-3">
                                    <Link to={`/details/${propertyNavigation.previousPropertyReference}`}>
                                        {"<"} Vorherige
                                    </Link>
                                </li>
                            )}

                            <li className="border-r-2 px-3">
                                <Link to="/">Zurück zur Liste</Link>
                            </li>

                            {propertyNavigation?.nextPropertyReference && (
                                <li className=" px-2">
                                    <Link to={`/details/${propertyNavigation.nextPropertyReference}`}>
                                        Nächste {">"}
                                    </Link>
                                </li>
                            )}
                        </ul>

                        <Carousel images={propertyData.images} />

                        <section
                            className="flex flex-col items-center gap-1 lg:gap-0 py-4 content-container justify-center
                 lg:items-baseline 
                flex-wrap relative">
                            <div className="font-medium flex flex-col gap-1  items-center justify-center w-full lg:flex-row lg:justify-between lg:mb-4">
                                <h1 className="lg:text-left lg:text-3xl font-medium text-xl text-center">
                                    {propertyData.shortDescription}
                                </h1>

                                <p className="font-semibold text-xs lg:text-base">
                                    EUR {propertyData.price}
                                </p>
                            </div>
                            <h2 className=" lg:text-2xl font-normal italic lg:mb-4">
                                {propertyData.location}
                            </h2>

                            <ul className="flex flex-row gap-8 items-center lg:self-end">
                                <li>
                                    <IconInfo filled={true} name="home" dir="horizontal">
                                        {propertyData.area} m2
                                    </IconInfo>
                                </li>

                                <li>
                                    <IconInfo filled={true} name="room_preferences" dir="horizontal">
                                        {propertyData.community}
                                    </IconInfo>
                                </li>

                                <li>
                                    <IconInfo filled={true} name="king_bed" dir="horizontal">
                                        {" "}
                                        {propertyData.beds}
                                    </IconInfo>
                                </li>
                                <li>
                                    <IconInfo name="bathtub" dir="horizontal">
                                        {" "}
                                        {propertyData.numberOfBathRooms}
                                    </IconInfo>
                                </li>
                            </ul>
                        </section>
                    </header>

                    <main className="flex flex-col lg:flex-row gap-x-16 text-secondary content-container">
                        <article className="lg:w-2/3">
                            <h2 className="text-2xl font-semibold mb-4">Beschreibung</h2>
                            <p>{propertyData.longDescription}</p>

                            <h1>Eigenschaften</h1>
                            <CharacteristicsList options={property.options.option} />
                        </article>

                        <aside className="lg:w-1/3">
                            <button onClick={handleProperty} className="btn btn-full">
                                Ich bin interessiert
                            </button>

                            <br />
                            <br />
                            <Contact></Contact>
                            <h1>Eigenschaften</h1>

                            <ListInfo selectedProperty={property} />
                        </aside>
                    </main>
                </>
            )}
        </>
    );
};

export default Details;
