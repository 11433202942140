import React from 'react';
import ReactTooltip from 'react-tooltip';

interface IconProps {
    name: string;
    children: React.ReactNode;
    hoverable?: boolean;
    hoverableString?: string;
    filled?: boolean;
    dir?: 'vertical' | 'horizontal';
    hoverText?: string;
}
const IconInfo = ({ name, hoverable, filled, dir = 'vertical', children, hoverText }: IconProps) => {

    return (
        <>
            <div data-tip={hoverable} data-for={hoverable ? hoverText : ''} className={`cursor-default  items-center  flex ${dir === 'vertical' ? 'flex-row gap-x-2' : 'flex-col justify-center'}`}>
                <span className={`material-symbols-outlined ${filled ? 'filled' : ''}`}>
                    {name}
                </span>
                <span>
                    {children}
                </span>
            </div>
            {<ReactTooltip place='bottom' type='light' id={hoverText}>{hoverText}</ReactTooltip>
            }

        </>

    )
}

export default IconInfo;