import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import CardsGrid from '../components/cards-grid';
import FiltersWraper from '../components/filters/filters.wraper';
import PropertyCard from '../components/property-card';
import { Actions, PropertiesContext } from '../context/properties.context';

const Home = () => {

    const { state, dispatch } = React.useContext(PropertiesContext);
    const { allData, pageSize, pagedData, filteredData, pageCount } = state;
    const handlePageClick = (event: any) => {
        const selectedPage = event.selected + 1;
        dispatch({
            type: Actions.PageChanged,
            payload: selectedPage
        })
    };

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', left: 0, top: 0 })
    }, [state.pageNumber])

    return <>

        {
            allData.length > 0 &&
            <FiltersWraper></FiltersWraper>
        }
        <div className="App content-container pb-12 pt-8">

            {allData.length > 0 &&
                <h2 className="text-2xl font-sans font-semibold md:text-left text-center  mb-5">{filteredData.length} Resultate</h2>
            }

            {filteredData.length > 0 ?
                <CardsGrid>
                    {pagedData.map((item: any, i: number) => (
                        <PropertyCard key={i} mitem={item} idx={i} />
                    ))}
                </CardsGrid>
                :
                !state.loading && <div className='hero w-full'>“Keine Resultate” and “Keine Ergebnisse”</div>}

            {allData.length > 0 && pageCount > 1 &&
                <ReactPaginate
                    className='pagination'
                    breakLabel="..."
                    nextLabel=">"
                    initialPage={state.pageNumber - 1 > 0 ? state.pageNumber - 1 : 0}  // something is modifying the page number on home mount
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(filteredData.length / pageSize)}
                    previousLabel="<"
                />
            }
        </div>
    </>
}

export default Home;