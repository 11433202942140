import React from 'react'

type Props = {}

function Contact({ }: Props) {
    return (

        <div className="">
            <h1>Besichtigungskontakt</h1>
            <div className="flex">
                <div className="w-40">
                    <picture className="item" title="">
                        <img alt="Lifestyle Company" title="Lifestyle Company" src="/logoLifStyleCompany.jpg" />
                    </picture>
                </div>

                <div className="visit_infos leading-tight">
                    <h1 className="presentation_company">Lifestyle Company <br /></h1>
                    <h4 className="presentation_name"> Lifestyle Company</h4>
                    <div className="button display_visit">

                    </div>
                    <div className="visit_info_container hide_visit_content">
                        <div className="row row_address">Hallwylstrasse 48</div>
                        <div className="row row_city">3005 Bern</div><div className="row row_phone">
                            <a href="tel:+41313513031"><span className="row_info_label">Tel. </span>+41 31 351 30 31</a>
                        </div>
                        <div className="row row_email">
                            <a href="mailto:info@lifestyle-company.ch">info@lifestyle-company.ch</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    )
}

export default Contact

