import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconInfo from './icon-info';
import { Actions, PropertiesContext } from '../context/properties.context';
import { Property } from '../types/Models';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ mitem, idx }: { mitem: Property, idx: number }) => {

    const { dispatch } = React.useContext(PropertiesContext);
    const navigate = useNavigate();
    const image = (mitem?.images?.find(r => r.main === 'true') || mitem.images[0]).url;
    const shortDescription = mitem?.descriptions.short_description._
    const location = mitem?.city.value
    const price = (+mitem?.selling_price_eur).toLocaleString()
    const area = mitem?.mts_build
    const bedRooms = mitem?.nr_bedrooms
    const buildDate = mitem?.dt_construction_year
    const reference = mitem?.reference

    return (

        <>
            {mitem &&
                <article onClick={(e) => {
                    e.preventDefault();
                    dispatch({
                        type: Actions.PropertySelection,
                        payload: idx
                    });
                    navigate(`/details/${reference}`);
                }} className="cursor-pointer group bg-primary lg:max-h-[500px] max-h-[600px]  text-secondary hover:bg-secondary hover:text-white transition-colors
        
        flex flex-col">
                    <img className="lg:h-[40%] object-cover md:h-1/2 h-[60%]" src={image} alt="" />

                    <section className="p-5 flex flex-col gap-y-[15px]">
                        <h2 className="text-xl lg:text-2xl break-words font-medium">{shortDescription}</h2>
                        <ul className="flex gap-y-1 flex-col">

                            <li>

                                <IconInfo name="location_on"  >
                                    {location}
                                </IconInfo>
                            </li>
                            <li>
                                <IconInfo name="euro"  >
                                    {price}
                                </IconInfo>
                            </li>
                            <li>
                                <IconInfo name="home">
                                    {area} m<sup>2</sup>

                                </IconInfo>
                            </li>
                            <li>
                                <IconInfo name="bed"  >
                                    {bedRooms}
                                </IconInfo>
                            </li>
                            {buildDate &&
                                <li>
                                    <IconInfo name="calendar_month"  >
                                        {buildDate}
                                    </IconInfo>

                                </li>}

                        </ul>
                    </section>

                </article>}
        </>)
}