import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination";
import './carousel.css'
interface CarouselProps {
    images?: string[];
}
const Carousel = (props: CarouselProps) => {
    return (
        <Swiper
            modules={[Pagination, Navigation]}
            lazy={true}
            autoplay={true}
            loop={true}
            spaceBetween={5}
            navigation={true}
            breakpoints={{
                400: {
                    slidesPerView: 1,
                    centeredSlides: false,
                },
                640: {
                    slidesPerView: 1,
                    centeredSlides: false,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                    centeredSlides: true,
                },
                1600: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                    centeredSlides: true,
                }
            }
            }
            pagination={{
                clickable: true,
            }}
        >
            {props && props.images && props.images.map((src, i) => {
                return <SwiperSlide key={i}>
                    <img src={src} alt="carousel" key={i} height='1400' width='auto' style={{ maxHeight: '400px', minHeight: '400px', width: '100%', objectFit: 'cover' }} />
                </SwiperSlide>
            })}
        </Swiper>
    );
};

export default Carousel;