import React from 'react';
import './input.css';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    loading?: boolean;
}
const Input = (props: InputProps) => {
    return (
        <>
            <div className="input-container  form-control">


                <input {...props} id="firstname" className="input text-sm" type="text" placeholder=" " />
                <label htmlFor="firstname" className="placeholder text-sm text-secondary">{props.placeholder}</label>
            </div>
        </>
    )
}

export default Input;