import React from 'react'
import Slider from 'rc-slider';
type Props = {
    min: number,
    max: number,
    step: number,
    value: number[],
    onAfterChange: Function
}


export default function RangeComp({ min, max, step, value, onAfterChange }: Props) {
    return (
        <div className="h-8 flex items-center">

            <Slider
                range
                min={min}
                max={max}
                step={step}
                value={value || [min, max]}
                defaultValue={[min, max]}
                allowCross={false}

                onAfterChange={(range) => { onAfterChange(range) }

                }
                onChange={(range: any) => {
                    onAfterChange(range)
                }
                }
            />


        </div>

    )

}